// App.js

import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import './styles/Animations.css'; // Import the animation styles
import Sidebar from './components/Sidebar';
import MapSection from './components/MapSection';
import BottomSection from './components/BottomSection';
import SuggestionSection from './components/SuggestionSection';
import TopSection from './components/TopSection';
import CalendarView from './components/CalendarView'; // Import CalendarView component
import AdminDemandUpdater from './components/AdminDemandUpdater';
import axios from 'axios';
import {
  startOfDay,
  endOfDay,
  parseISO,
  isWithinInterval,
  isValid,
} from 'date-fns';

// Utility functions for distance calculation
function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1); // Corrected order
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
}

function deg2rad(deg) {
  return (deg * Math.PI) / 180;
}

function App() {
  // Date state
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [currentUserId, setCurrentUserId] = useState(null);

  // Content visibility states
  // Removed contentVisible and contentAnimating
  const [priceSuggestion, setPriceSuggestion] = useState(null);
  const [isSuggestionBoxVisible, setIsSuggestionBoxVisible] = useState(false);

  // Button state: 'idle' | 'loading' | 'completed'
  const [suggestionButtonState, setSuggestionButtonState] = useState('idle');

  const contentRef = useRef(null);
  const sidebarRef = useRef(null);

  // Filter states
  const [filters, setFilters] = useState({
    Zene: false,
    Kultúra: false,
    Sport: false,
    Színház: false,
  });

  // Data states
  const [accommodations, setAccommodations] = useState([]);
  const [events, setEvents] = useState([]);
  const [venues, setVenues] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Computed data states
  const [filteredVenues, setFilteredVenues] = useState([]);

  // Selected states
  const [selectedAccommodation, setSelectedAccommodation] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Map center state
  const [mapCenter, setMapCenter] = useState([19.0402, 47.4979]); // [longitude, latitude]

  // State for toggling BottomSection on mobile
  const [isBottomSectionOpen, setIsBottomSectionOpen] = useState(true);

  // State for current view
  const [currentView, setCurrentView] = useState('events-map');

  // Computed values state
  const [eventsCount, setEventsCount] = useState(0);
  const [eventImportance, setEventImportance] = useState(0);
  const [holidayNames, setHolidayNames] = useState([]);
  const [holidayEventInteraction, setHolidayEventInteraction] = useState(0);
  const [holidayEventImportance, setHolidayEventImportance] = useState(0);
  const [dayOfWeek, setDayOfWeek] = useState(0);
  const [isWeekend, setIsWeekend] = useState(0);
  const [daysToNextHoliday, setDaysToNextHoliday] = useState(0);

  useEffect(() => {
    if (window.WPAPI && window.WPAPI.userID) {
      setCurrentUserId(parseInt(window.WPAPI.userID, 10)); // Ensure it's a number
    } else {
      console.error('WPAPI is not defined or userID is missing.');
    }
  }, []);

  // Fetch events, venues, accommodations, and holidays on initial load
  useEffect(() => {
    const fetchData = async () => {
      // Fetch Venues
      try {
        const venuesResponse = await axios.get('/wp-json/wp/v2/custom_venues');
        setVenues(venuesResponse.data || []);
        console.log('Fetched Venues:', venuesResponse.data);
      } catch (error) {
        console.error('Error fetching venues:', error);
        setVenues([]);
      }

      // Fetch Accommodations
      try {
        const accommodationsResponse = await axios.get(
          '/wp-json/wp/v2/user_accommodations',
          {
            headers: { 'X-WP-Nonce': window.WPAPI?.nonce },
          }
        );
        setAccommodations(accommodationsResponse.data || []);
        console.log('Fetched Accommodations:', accommodationsResponse.data);
      } catch (error) {
        console.error('Error fetching accommodations:', error);
        setAccommodations([]);
      }

      // Fetch Events
      try {
        const eventsResponse = await axios.get('/wp-json/wp/v2/events');
        setEvents(eventsResponse.data || []);
        console.log('Fetched Events:', eventsResponse.data);
      } catch (error) {
        console.error('Error fetching events:', error);
        setEvents([]);
      }

      // Fetch Holidays
      try {
        const holidaysResponse = await axios.get('/wp-json/wp/v2/holidays');
        setHolidays(holidaysResponse.data || []);
        console.log('Fetched Holidays:', holidaysResponse.data);
      } catch (error) {
        console.error('Error fetching holidays:', error);
        setHolidays([]);
      }
    };

    fetchData();
  }, []);

  // Filter events and set filteredVenues
  useEffect(() => {
    if (venues.length > 0 && events.length > 0 && selectedAccommodation) {
      const accommodationLatitude = parseFloat(selectedAccommodation.latitude);
      const accommodationLongitude = parseFloat(selectedAccommodation.longitude);

      const selectedDayStart = startOfDay(startDate);
      const selectedDayEnd = endOfDay(startDate);

      // Filter events based on the selected date
      let filteredEvents = events.filter((event) => {
        const eventStartStr = event.event_start.replace(' ', 'T');
        const eventDate = parseISO(eventStartStr);

        if (!isValid(eventDate)) {
          console.warn('Invalid event date:', event.event_start);
          return false;
        }

        return isWithinInterval(eventDate, {
          start: selectedDayStart,
          end: selectedDayEnd,
        });
      });

      // Filter events based on selected filters
      const activeFilters = Object.keys(filters).filter((key) => filters[key]);
      if (activeFilters.length > 0) {
        filteredEvents = filteredEvents.filter((event) =>
          activeFilters.includes(event.event_type)
        );
      }

      // Map venues with their events and calculate distance
      const filteredVenuesData = venues
        .map((venue) => {
          const venueLatitude = parseFloat(venue.latitude);
          const venueLongitude = parseFloat(venue.longitude);
          const distanceInKm = getDistanceFromLatLonInKm(
            accommodationLatitude,
            accommodationLongitude,
            venueLatitude,
            venueLongitude
          );

          const eventsAtVenue = filteredEvents.filter(
            (event) => event.event_venue === venue.venue_name
          );

          return {
            ...venue,
            distanceInKm,
            events: eventsAtVenue,
          };
        })
        .filter((venue) => venue.events.length > 0);

      setFilteredVenues(filteredVenuesData);
    } else {
      setFilteredVenues([]);
    }
  }, [venues, events, startDate, selectedAccommodation, filters]);

  // Removed scroll to sidebar logic

  /* Removed handleContentVisibility function */

  // Function to handle date changes from CalendarView
  const handleDateChange = async (date) => {
    if (isLoading) return;

    if (!selectedAccommodation) {
      console.error('No accommodation selected.');
      return;
    }

    setIsLoading(true);
    setStartDate(date);

    await handleGetPriceSuggestion(date);
  };

  // Function to compute variables needed for price suggestion
  const computeVariables = (date, selectedAccommodation) => {
    let eventsCountValue = 0;
    let eventImportanceValue = 0;
    let holidayNamesValue = [];
    let holidayEventInteractionValue = 1;
    let holidayEventImportanceValue = 1;
    let dayOfWeekValue = 0;
    let isWeekendValue = 0;
    let daysToNextHolidayValue = 0;

    if (
      events.length > 0 &&
      selectedAccommodation &&
      holidays.length >= 0 && // Allow for zero holidays
      venues.length > 0
    ) {
      // 1. Compute eventsCount
      const selectedDayStart = startOfDay(date);
      const selectedDayEnd = endOfDay(date);

      const eventsOnSelectedDate = events.filter((event) => {
        const eventStartStr = event.event_start.replace(' ', 'T');
        const eventDate = parseISO(eventStartStr);

        if (!isValid(eventDate)) {
          console.warn('Invalid event date:', event.event_start);
          return false;
        }

        return isWithinInterval(eventDate, {
          start: selectedDayStart,
          end: selectedDayEnd,
        });
      });

      eventsCountValue = eventsOnSelectedDate.length;
      console.log('Events Count:', eventsCountValue); // Debugging

      // **Include highRatedEvents**
      const highRatedEvents = eventsOnSelectedDate.filter((event) => {
        const eventRating = parseFloat(event.event_rating) || 0;
        return eventRating >= 6;
      });

      // 2. Compute eventImportance by summing adjusted ratings with multipliers
      let totalAdjustedRating = 0;

      highRatedEvents.forEach((event) => {
        const venue = venues.find((v) => v.venue_name === event.event_venue);
        if (venue) {
          const venueLongitude = parseFloat(venue.longitude);
          const venueLatitude = parseFloat(venue.latitude);
          const accommodationLongitude = parseFloat(
            selectedAccommodation.longitude
          );
          const accommodationLatitude = parseFloat(
            selectedAccommodation.latitude
          );

          const distanceInKm = getDistanceFromLatLonInKm(
            accommodationLatitude,
            accommodationLongitude,
            venueLatitude,
            venueLongitude
          );

          // Corrected distance factor calculation
          let distanceFactor = 1.1; // Max factor for closest events
          if (distanceInKm >= 0 && distanceInKm <= 10) {
            distanceFactor = 1.01 + ((10 - distanceInKm) * 0.09) / 10;
          } else if (distanceInKm > 10) {
            distanceFactor = 1.01; // Min factor for distant events
          }

          const eventRating = parseFloat(event.event_rating) || 0;

          // **Determine Multiplier Based on Event Rating**
          let multiplier = 1; // Default multiplier
          switch (Math.round(eventRating)) {
            case 10:
              multiplier = 3.5;
              break;
            case 9:
              multiplier = 2.5;
              break;
            case 8:
              multiplier = 1.5;
              break;
            case 7:
              multiplier = 1.25;
              break;
            case 6:
              multiplier = 1;
              break;
            default:
              multiplier = 1; // Fallback for unexpected ratings
          }

          const adjustedRating = eventRating * distanceFactor * multiplier;
          totalAdjustedRating += adjustedRating;

          console.log(
            `Event: ${event.event_name}, Rating: ${eventRating}, Multiplier: ${multiplier}, Adjusted Rating: ${adjustedRating}`
          ); // Debugging
        }
      });

      // **Divide totalAdjustedRating by highRatedEvents.length**
      if (highRatedEvents.length > 0) {
        eventImportanceValue = totalAdjustedRating / highRatedEvents.length;
      }
      console.log('Event Importance:', eventImportanceValue);

      // 3. Compute holidayEventInteraction

      // 3.1 Define holidaysOnSelectedDate
      const holidaysOnSelectedDate = holidays.filter((holiday) => {
        const holidayStart = parseISO(holiday.start_date);
        const holidayEnd = holiday.end_date
          ? parseISO(holiday.end_date)
          : holidayStart;

        if (!isValid(holidayStart)) {
          console.warn('Invalid holiday start date:', holiday.start_date);
          return false;
        }

        if (!isValid(holidayEnd)) {
          console.warn('Invalid holiday end date:', holiday.end_date);
          return false;
        }

        return isWithinInterval(selectedDayStart, {
          start: startOfDay(holidayStart),
          end: endOfDay(holidayEnd),
        });
      });

      // 3.2 Group holidays by name
      const holidayGroups = holidaysOnSelectedDate.reduce((groups, holiday) => {
        const name = holiday.holiday;
        if (!groups[name]) {
          groups[name] = [];
        }
        groups[name].push(holiday);
        return groups;
      }, {});

      // 3.3 Sum 'change' values for holidays with the same name
      const summedChanges = Object.values(holidayGroups).map((group) => {
        return group.reduce((sum, holiday) => {
          const changeValue = parseFloat(holiday.change) || 0; // Default to 0
          return sum + changeValue;
        }, 0);
      });

      // 3.4 Average the summed changes
      const totalChange = summedChanges.reduce((sum, val) => sum + val, 0);

      // 3.5 Calculate holidayEventInteraction
      const numberOfHolidayGroups = Object.keys(holidayGroups).length;
      holidayEventInteractionValue =
        numberOfHolidayGroups > 0
          ? totalChange / numberOfHolidayGroups
          : eventImportanceValue || 1; // Set to 1 if no holidays
      console.log(
        'Holiday Event Interaction:',
        holidayEventInteractionValue
      ); // Debugging

      // 3.6 Compute holidayEventImportance with adjusted logic
      if (eventImportanceValue === 0) {
        holidayEventImportanceValue = holidayEventInteractionValue;
      } else {
        holidayEventImportanceValue =
          (holidayEventInteractionValue * (1 + eventImportanceValue * 2)) / 10;
      }
      console.log('Holiday Event Importance:', holidayEventImportanceValue); // Debugging

      // 3.7 Compute holidayNamesValue as list of unique holiday names
      holidayNamesValue = Object.keys(holidayGroups);
      console.log('Holidays Count:', holidayNamesValue.length); // Debugging

      // 4. Compute dayOfWeek (1 for Monday, 7 for Sunday)
      const jsDayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
      dayOfWeekValue = jsDayOfWeek === 0 ? 7 : jsDayOfWeek;
      console.log('Day of Week:', dayOfWeekValue); // Debugging

      // 5. Compute isWeekend
      isWeekendValue = dayOfWeekValue >= 6 ? 1 : 0;
      console.log('Is Weekend:', isWeekendValue); // Debugging

      // 6. Compute daysToNextHoliday
      const futureHolidays = holidays
        .map((holiday) => {
          const holidayStart = startOfDay(parseISO(holiday.start_date));
          return {
            ...holiday,
            holidayStart,
          };
        })
        .filter((holiday) => {
          if (!isValid(holiday.holidayStart)) {
            console.warn('Invalid holiday start date:', holiday.start_date);
            return false;
          }

          return holiday.holidayStart > date; // Exclude holidays on or before the selected date
        });

      futureHolidays.sort((a, b) => {
        return a.holidayStart - b.holidayStart;
      });

      const nextHoliday = futureHolidays[0];

      daysToNextHolidayValue = nextHoliday
        ? Math.ceil(
            (nextHoliday.holidayStart - date) / (1000 * 60 * 60 * 24)
          )
        : 0; // If no future holidays, set to 0

      console.log('Days to Next Holiday:', daysToNextHolidayValue); // Debugging

      return {
        eventsCountValue,
        eventImportanceValue,
        holidayNamesValue,
        holidayEventInteractionValue,
        holidayEventImportanceValue,
        dayOfWeekValue,
        isWeekendValue,
        daysToNextHolidayValue,
      };
  };
  };
  // Function to handle getting the price suggestion
  const handleGetPriceSuggestion = async (date) => {
    if (!selectedAccommodation) {
      console.error('No accommodation selected.');
      setIsLoading(false);
      return;
    }

    try {
      setSuggestionButtonState('loading'); // Set to loading
      setIsLoading(true);

      const prevDayPriceChange = 0; // Set to 0 as per instructions
      const prev3DayAvgPriceChange = 0; // Set to 0 as per instructions

      // Compute variables here using the passed date
      const {
        eventsCountValue,
        eventImportanceValue,
        holidayNamesValue,
        holidayEventInteractionValue,
        holidayEventImportanceValue,
        dayOfWeekValue,
        isWeekendValue,
        daysToNextHolidayValue,
      } = computeVariables(date, selectedAccommodation);

      // Store computed variables in state
      setEventsCount(eventsCountValue);
      setEventImportance(eventImportanceValue);
      setHolidayNames(holidayNamesValue);
      setHolidayEventInteraction(holidayEventInteractionValue);
      setHolidayEventImportance(holidayEventImportanceValue);
      setDayOfWeek(dayOfWeekValue);
      setIsWeekend(isWeekendValue);
      setDaysToNextHoliday(daysToNextHolidayValue);

      // Prepare data for the backend
      const requestData = {
        events_count: eventsCountValue || 0,
        month: date.getMonth() + 1,
        day: date.getDate(),
        holiday: holidayNamesValue.length > 0 ? 1 : 0,
        event_importance: eventImportanceValue || 0,
        day_of_week: dayOfWeekValue,
        holiday_event_interaction: holidayEventInteractionValue || 1,
        holiday_event_importance: holidayEventImportanceValue || 1,
        prev_day_price_change: prevDayPriceChange || 0,
        prev_3_day_avg_price_change: prev3DayAvgPriceChange || 0,
        is_weekend: isWeekendValue ? 1 : 0,
        days_to_next_holiday: daysToNextHolidayValue || 0,
      };

      console.log('Sending data to backend:', requestData);

      // Send the request to the backend
      const response = await axios.post(
        'https://db74-139-144-66-74.ngrok-free.app/get_price_suggestion',
        requestData
      );

      console.log('Response from API:', response);

      if (response.data && response.data.suggestion !== undefined) {
        setPriceSuggestion(response.data.suggestion);
        setIsSuggestionBoxVisible(true); // Show the suggestion box
        setSuggestionButtonState('completed'); // Set to completed
      } else {
        console.error('Unexpected response format:', response);
        setSuggestionButtonState('error'); // Optional: handle error
      }
    } catch (error) {
      console.error('Error fetching price suggestion:', error);
      setSuggestionButtonState('error'); // Optional: handle error
    } finally {
      setIsLoading(false);
    }
  };

  // Function to reset suggestion when accommodation or date changes
  useEffect(() => {
    setPriceSuggestion(null);
    setIsSuggestionBoxVisible(false);
    setSuggestionButtonState('idle'); // Reset button state
  }, [selectedAccommodation, startDate]);

  // Function to reset suggestion manually
  const handleResetSuggestion = () => {
    setPriceSuggestion(null);
    setIsSuggestionBoxVisible(false);
    setSuggestionButtonState('idle');
  };

  if (currentUserId === null) {
    // You can display a loading indicator here if you prefer
    return null;
  }

 return (
    <div className="App">
      {/* MapSection is now positioned behind all other components */}
      <MapSection
        selectedAccommodation={selectedAccommodation}
        accommodations={accommodations}
        filteredVenues={filteredVenues}
        mapCenter={mapCenter}
        setMapCenter={setMapCenter}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        isBottomSectionOpen={isBottomSectionOpen}
      />

      {/* Use the merged TopSection component */}
      <TopSection
        accommodations={accommodations}
        selectedAccommodation={selectedAccommodation}
        setSelectedAccommodation={setSelectedAccommodation}
        setMapCenter={setMapCenter}
        currentView={currentView}
        setCurrentView={setCurrentView}
        startDate={startDate}
        setStartDate={setStartDate}
        filters={filters}
        setFilters={setFilters}
        handleGetPriceSuggestion={handleGetPriceSuggestion}
        suggestionButtonState={suggestionButtonState}
        handleResetSuggestion={handleResetSuggestion}
      />

      {/* SuggestionSection positioned above main-content-horizontal */}
      <SuggestionSection
        eventsCount={eventsCount}
        eventImportance={eventImportance}
        holidayNames={holidayNames}
        holidayEventInteraction={holidayEventInteraction}
        holidayEventImportance={holidayEventImportance}
        prevDayPriceChange={0} // Set to 0 or update as needed
        prev3DayAvgPriceChange={0} // Set to 0 or update as needed
        isWeekend={isWeekend}
        daysToNextHoliday={daysToNextHoliday}
        selectedAccommodation={selectedAccommodation}
        startDate={startDate} // Pass startDate
        priceSuggestion={priceSuggestion} // Pass the suggestion
        isSuggestionBoxVisible={isSuggestionBoxVisible}
        handleResetSuggestion={handleResetSuggestion}
      />

      {/* Main Content */}
      <div className="content-container">
        <div
          className={`main-content-horizontal ${
            isBottomSectionOpen ? 'bottom-open' : 'bottom-closed'
          }`}
        >
          {/* BottomSection is always visible */}
          <BottomSection
            filteredVenues={filteredVenues}
            setMapCenter={setMapCenter}
            setSelectedEvent={setSelectedEvent}
            holidays={holidays}
            startDate={startDate}
          />

          {/* CalendarView is conditionally rendered based on currentView */}
          {currentView === 'calendar' && (
            <CalendarView
              startDate={startDate}
              setStartDate={setStartDate}
              events={events}
              holidays={holidays}
              selectedAccommodation={selectedAccommodation}
              handleDateChange={handleDateChange}
              SuggestionSectionProps={{
                eventsCount: eventsCount,
                eventImportance: eventImportance,
                holidayNames: holidayNames,
                holidayEventInteraction: holidayEventInteraction,
                holidayEventImportance: holidayEventImportance,
                prevDayPriceChange: 0,
                prev3DayAvgPriceChange: 0,
                isWeekend: isWeekend,
                daysToNextHoliday: daysToNextHoliday,
                selectedAccommodation,
                startDate,
                priceSuggestion,
                isSuggestionBoxVisible,
                handleResetSuggestion,
              }}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              suggestionButtonState={suggestionButtonState}
              handleResetSuggestion={handleResetSuggestion}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default App;