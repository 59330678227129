// MapSection.js

import React, { useState, useRef, useEffect } from 'react';
import Map, { Marker, Popup, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import './MapSection.css'; // Import the CSS file
import ResizeObserver from 'resize-observer-polyfill'; // Import ResizeObserver polyfill
import debounce from 'lodash.debounce'; // Import debounce

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MapSection = ({
  accommodations,
  filteredVenues,
  selectedAccommodation,
  setSelectedAccommodation,
  selectedEvent,
  setSelectedEvent,
  mapCenter,
  setMapCenter,
  isBottomSectionOpen, // Receive the prop to monitor BottomSection state
}) => {
  const mapRef = useRef(); // Reference to the Map component
  const [popupInfo, setPopupInfo] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const bounds = [
    [18.6612, 47.3516],
    [19.5575, 47.6073],
  ];

  // Handle map load event
  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  // Pan to selected event's venue when selectedEvent changes
  useEffect(() => {
    if (selectedEvent && mapLoaded) {
      const { venue } = selectedEvent;
      if (venue) {
        const longitude = parseFloat(venue.longitude);
        const latitude = parseFloat(venue.latitude);

        // Fly to the selected event's location
        mapRef.current.getMap().flyTo({
          center: [longitude, latitude],
          zoom: 13,
          essential: true,
        });

        // Set popup information to display
        setPopupInfo({
          ...venue,
          events: [selectedEvent],
        });
      }
    }
  }, [selectedEvent, mapLoaded]);

  // Pan to selected accommodation when selectedAccommodation changes
  useEffect(() => {
    if (selectedAccommodation && mapLoaded) {
      const longitude = parseFloat(selectedAccommodation.longitude);
      const latitude = parseFloat(selectedAccommodation.latitude);

      // Fly to the selected accommodation's location
      mapRef.current.getMap().flyTo({
        center: [longitude, latitude],
        zoom: 13,
        essential: true,
      });

      // Optionally show popup for accommodation
      setPopupInfo(selectedAccommodation);
    }
  }, [selectedAccommodation, mapLoaded]);

  // Pan to mapCenter when it changes
  useEffect(() => {
    if (mapCenter && mapLoaded) {
      const [longitude, latitude] = mapCenter;
      mapRef.current.getMap().flyTo({
        center: [parseFloat(longitude), parseFloat(latitude)],
        essential: true,
      });
    }
  }, [mapCenter, mapLoaded]);

  // Handle resizing when isBottomSectionOpen changes
  useEffect(() => {
    if (mapRef.current && mapLoaded) {
      // Slight delay to allow layout changes to take effect
      setTimeout(() => {
        mapRef.current.getMap().resize();
      }, 300); // Adjust the delay as needed
    }
  }, [isBottomSectionOpen, mapLoaded]);

  // Handle dynamic resizing with ResizeObserver and debounce
  useEffect(() => {
    if (!mapRef.current || !mapRef.current.getMap) return;

    // Debounced resize handler to prevent excessive calls
    const handleResize = debounce(() => {
      if (mapRef.current) {
        mapRef.current.getMap().resize();
      }
    }, 200); // 200ms debounce

    const resizeObserver = new ResizeObserver(handleResize);
    const mapContainer = mapRef.current.getMapContainer();

    if (mapContainer) {
      resizeObserver.observe(mapContainer);
    }

    // Clean up observer and debounce on unmount
    return () => {
      if (mapContainer) {
        resizeObserver.unobserve(mapContainer);
      }
      resizeObserver.disconnect();
      handleResize.cancel();
    };
  }, []);

  return (
    <div className="MapSection"> {/* Added wrapper with className */}
      <Map
        initialViewState={{
          longitude: 19.0402,
          latitude: 47.4979,
          zoom: 12,
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/smartbuild24/cm0ggac6d002301pj1u0pfqq9"
        ref={mapRef}
        onLoad={handleMapLoad}
        maxBounds={bounds}
        maxZoom={18}
        minZoom={11}
        maxPitch={70}
        attributionControl={false}
      >
        <NavigationControl position="top-right" />
        {mapLoaded &&
          accommodations.map((accommodation) => (
            <Marker
              key={accommodation.id}
			  className="custom-house"
              longitude={parseFloat(accommodation.longitude)}
              latitude={parseFloat(accommodation.latitude)}
              onClick={(e) => {
                // Prevent map zoom when clicking on marker
                e.originalEvent.stopPropagation();
                setPopupInfo(accommodation);
              }}
            >
              <img
                src="/wp-content/icons/house.png"
                alt="Accommodation"
                style={{ width: 40, height: 40 }}
              />
            </Marker>
          ))}

        {mapLoaded &&
          filteredVenues.map((venue) => (
            <Marker
              key={venue.id}
			  className="custom-marker"
              longitude={parseFloat(venue.longitude)}
              latitude={parseFloat(venue.latitude)}
              onClick={(e) => {
                // Prevent map zoom when clicking on marker
                e.originalEvent.stopPropagation();
                setPopupInfo(venue);
              }}
            >
              <img
                src="/wp-content/icons/location.png"
                alt="Venue"
                style={{ width: 25, height: 25 }}
              />
            </Marker>
          ))}

        {popupInfo && (
          <Popup
            longitude={parseFloat(popupInfo.longitude)}
            latitude={parseFloat(popupInfo.latitude)}
            anchor="bottom"
            offset={[0, -15]}
            closeOnClick={false}
            onClose={() => setPopupInfo(null)}
            className="custom-popup"
          >
            <div className="popup-content">
              {popupInfo.szallas_nev && (
                <div>
                  <strong>{popupInfo.szallas_nev}</strong>
                  <br />
                  {popupInfo.szallas_cim}
                </div>
              )}
              {popupInfo.venue_name && (
                <div>
                  <strong>{popupInfo.venue_name}</strong>
                  <br />
                  {popupInfo.venue_address}
                  <br />
                  {`Ezen a napon ${popupInfo.events.length} esemény lesz itt:`}
                  <br />
                  <ul>
                    {popupInfo.events.map((event) => (
                      <li key={event.id}>
                        <strong>{event.event_name}</strong>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </Popup>
        )}
      </Map>
    </div>
  );
};

export default MapSection;
