// Sidebar.js

import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { Calendar } from 'react-date-range';
import { format, addDays } from 'date-fns';
import { hu } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './Sidebar.css';

const Sidebar = forwardRef((props, ref) => {
  const {
    startDate,
    setStartDate,
    filters,
    setFilters,
    handleGetPriceSuggestion, // Receive from parent
    suggestionButtonState, // 'idle' | 'loading' | 'completed'
    handleResetSuggestion, // Function to reset suggestion
  } = props;

  const [calendarOpen, setCalendarOpen] = useState(false);
  const calendarRef = useRef(null);
  const dateInputRef = useRef(null);

  const eventIcons = {
    Zene: {
      colorless: '/wp-content/icons/microphone_colorless.png',
      colored: '/wp-content/icons/microphone.png',
    },
    Kultúra: {
      colorless: '/wp-content/icons/museum_colorless.png',
      colored: '/wp-content/icons/museum.png',
    },
    Sport: {
      colorless: '/wp-content/icons/sport_colorless.png',
      colored: '/wp-content/icons/sport.png',
    },
    Színház: {
      colorless: '/wp-content/icons/theatre_colorless.png',
      colored: '/wp-content/icons/theatre.png',
    },
  };

  const handleEventTypeClick = (type) => {
    setFilters({ ...filters, [type]: !filters[type] });
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setCalendarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Animated dots state
  const [dots, setDots] = useState('');

  useEffect(() => {
    let interval;
    if (suggestionButtonState === 'loading') {
      interval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + '.' : ''));
      }, 500);
    } else {
      setDots('');
    }
    return () => clearInterval(interval);
  }, [suggestionButtonState]);

  // Handle button click
  const handleButtonClick = () => {
    if (suggestionButtonState === 'idle') {
      handleGetPriceSuggestion(startDate); // Pass startDate here
    } else if (suggestionButtonState === 'completed') {
      // Handle reset suggestion
      handleResetSuggestion(); // Call the reset function from App.js
    }
  };

  return (
    <div className="sidebar-container" ref={ref}>
      {/* Left Column: Event Filters */}
      <div className="sidebar-left">
        <div className="event-filter-types">
          {Object.keys(eventIcons).map((type) => (
            <button
              key={type}
              className={`event-icon-button ${filters[type] ? 'active' : ''}`}
              onClick={() => handleEventTypeClick(type)}
              style={{
                backgroundImage: `url(${
                  filters[type] ? eventIcons[type].colored : eventIcons[type].colorless
                })`,
              }}
            />
          ))}
        </div>
      </div>

      {/* Right Column: Date Picker and Button */}
      <div className="sidebar-right">
        <div className="calendar-section" ref={calendarRef}>
          <div className="date-inputs" onClick={() => setCalendarOpen(!calendarOpen)}>
            <input
              type="text"
              value={format(startDate, 'yyyy-MM-dd')}
              readOnly
              ref={dateInputRef}
            />
          </div>
          {calendarOpen && (
            <div className="calendar-dropdown">
              <Calendar
                date={startDate}
                onChange={(date) => setStartDate(date)}
                minDate={new Date()}
                maxDate={addDays(new Date(), 365)}
                locale={hu}
                firstDayOfWeek={1}
              />
            </div>
          )}
        </div>
        <div className="suggestion-button-container">
          <button
            onClick={handleButtonClick}
            disabled={suggestionButtonState === 'loading'}
            className={`suggestion-button ${
              suggestionButtonState === 'loading' ? 'loading' :
              suggestionButtonState === 'completed' ? 'completed' : ''
            }`}
          >
            {/* The button text is controlled via CSS ::after pseudo-element */}
            {suggestionButtonState === 'idle' && 'Ár javaslat'}
            {/* When loading or completed, the text is managed via CSS */}
          </button>
        </div>
      </div>
    </div>
  );
});

export default Sidebar;
