// TopSection.js

import React, { useState, useRef, useEffect } from 'react';
import { Calendar } from 'react-date-range';
import { format, addDays } from 'date-fns';
import { hu } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './TopSection.css';

function TopSection(props) {
  const {
    accommodations,
    selectedAccommodation,
    setSelectedAccommodation,
    setMapCenter,
    currentView,
    setCurrentView,
    startDate,
    setStartDate,
    filters,
    setFilters,
    handleGetPriceSuggestion,
    suggestionButtonState,
    handleResetSuggestion,
  } = props;

  // State variables
  const [accommodationDropdownVisible, setAccommodationDropdownVisible] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [dots, setDots] = useState('');
  const [buttonsVisible, setButtonsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Refs
  const accommodationRef = useRef(null);
  const calendarRef = useRef(null);
  const dateInputRef = useRef(null);

  // Event icons
  const eventIcons = {
    Zene: {
      colorless: '/wp-content/icons/microphone_colorless.png',
      colored: '/wp-content/icons/microphone.png',
    },
    Kultúra: {
      colorless: '/wp-content/icons/museum_colorless.png',
      colored: '/wp-content/icons/museum.png',
    },
    Sport: {
      colorless: '/wp-content/icons/sport_colorless.png',
      colored: '/wp-content/icons/sport.png',
    },
    Színház: {
      colorless: '/wp-content/icons/theatre_colorless.png',
      colored: '/wp-content/icons/theatre.png',
    },
  };

  const handleAccommodationClick = (accommodation) => {
    setSelectedAccommodation(accommodation);
    setMapCenter([accommodation.longitude, accommodation.latitude]);
    setAccommodationDropdownVisible(false);
  };

  const handleEventTypeClick = (type) => {
    setFilters({ ...filters, [type]: !filters[type] });
  };

  const handleButtonClick = () => {
    if (suggestionButtonState === 'idle') {
      handleGetPriceSuggestion(startDate);
    } else if (suggestionButtonState === 'completed') {
      handleResetSuggestion();
    }
  };

  // Toggle between 'events-map' and 'calendar' views
  const handleToggleView = () => {
    if (currentView === 'events-map') {
      setCurrentView('calendar');
    } else {
      setCurrentView('events-map');
    }
    if (!buttonsVisible) {
      setButtonsVisible(true);
    }
  };

  const handleClickOutside = (event) => {
    if (accommodationRef.current && !accommodationRef.current.contains(event.target)) {
      setAccommodationDropdownVisible(false);
    }
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setCalendarOpen(false);
    }
  };
  
    useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedAccommodation) {
      setTimeout(() => setButtonsVisible(true), 0);
    } else {
      setButtonsVisible(false);
    }
  }, [selectedAccommodation]);

  // Animated dots state
  useEffect(() => {
    let interval;
    if (suggestionButtonState === 'loading') {
      interval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + '.' : ''));
      }, 500);
    } else {
      setDots('');
    }
    return () => clearInterval(interval);
  }, [suggestionButtonState]);

if (isMobile) {
    // Mobile Layout
    return (
      <div className="top-section">
        {/* First row: Accommodation selector */}
        <div className="accommodation-selector" ref={accommodationRef}>
          <button
            className={`dropdown-button ${selectedAccommodation ? 'active' : ''}`}
            onClick={() => setAccommodationDropdownVisible(!accommodationDropdownVisible)}
          >
            {selectedAccommodation
              ? selectedAccommodation.szallas_nev
              : 'Válassz a szállásaid közül!'}
          </button>
          {accommodationDropdownVisible && (
            <div className="dropdown-list accommodation-dropdown">
              {accommodations.length > 0 ? (
                accommodations.map((accommodation) => (
                  <div
                    key={accommodation.id}
                    className={`accommodation-item ${
                      selectedAccommodation === accommodation ? 'active' : ''
                    }`}
                    onClick={() => handleAccommodationClick(accommodation)}
                  >
                    <strong>{accommodation.szallas_nev}</strong>
                    <br />
                    <small>{accommodation.szallas_cim}</small>
                  </div>
                ))
              ) : (
                <p>Nincs még szállásod, adj hozzá egyet mielőtt tovább lépnél.</p>
              )}
            </div>
          )}
        </div>

        {/* Second row: Calendar and Suggestion Button */}
        <div className="calendar-and-suggestion">
          <div className="calendar-section" ref={calendarRef}>
            <div className="date-inputs" onClick={() => setCalendarOpen(!calendarOpen)}>
              <input
                type="text"
                value={format(startDate, 'yyyy-MM-dd')}
                readOnly
                ref={dateInputRef}
              />
            </div>
            {calendarOpen && (
              <div className="calendar-dropdown">
                <Calendar
                  date={startDate}
                  onChange={(date) => setStartDate(date)}
                  minDate={new Date()}
                  maxDate={addDays(new Date(), 365)}
                  locale={hu}
                  firstDayOfWeek={1}
                />
              </div>
            )}
          </div>

          <div className="suggestion-button-container">
            <button
              onClick={handleButtonClick}
              disabled={suggestionButtonState === 'loading'}
              className={`suggestion-button ${
                suggestionButtonState === 'loading'
                  ? 'loading'
                  : suggestionButtonState === 'completed'
                  ? 'completed'
                  : ''
              }`}
            >
              {suggestionButtonState === 'idle' && 'Ár javaslat'}
            </button>
          </div>
        </div>

        {/* Bottom row: Event Filters and Toggle View Button */}
        <div className="event-filters-and-toggle">
          <div className="event-filter-types">
            {Object.keys(eventIcons).map((type) => (
              <button
                key={type}
                className={`event-icon-button ${filters[type] ? 'active' : ''}`}
                onClick={() => handleEventTypeClick(type)}
              >
                <img
                  src={filters[type] ? eventIcons[type].colored : eventIcons[type].colorless}
                  alt={type}
                  className="event-icon-image"
                />
                <span className="event-icon-text">{type}</span>
              </button>
            ))}
          </div>

          {selectedAccommodation && (
            <div className={`view-switch-buttons ${buttonsVisible ? 'visible' : ''}`}>
              <button onClick={handleToggleView} className="toggle-view-button">
                {currentView === 'events-map' ? 'Kereslet naptár' : 'Vissza'}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    // Desktop Layout
    return (
    <div className="top-section">
      {/* Left side */}
      <div className="top-section-left">
        {/* Accommodation selector */}
        <div className="accommodation-selector" ref={accommodationRef}>
          <button
            className={`dropdown-button ${selectedAccommodation ? 'active' : ''}`}
            onClick={() => setAccommodationDropdownVisible(!accommodationDropdownVisible)}
          >
            {selectedAccommodation
              ? selectedAccommodation.szallas_nev
              : 'Válassz a szállásaid közül!'}
          </button>
          {accommodationDropdownVisible && (
            <div className="dropdown-list accommodation-dropdown">
              {accommodations.length > 0 ? (
                accommodations.map((accommodation) => (
                  <div
                    key={accommodation.id}
                    className={`accommodation-item ${
                      selectedAccommodation === accommodation ? 'active' : ''
                    }`}
                    onClick={() => handleAccommodationClick(accommodation)}
                  >
                    <strong>{accommodation.szallas_nev}</strong>
                    <br />
                    <small>{accommodation.szallas_cim}</small>
                  </div>
                ))
              ) : (
                <p>Nincs még szállásod, adj hozzá egyet mielőtt tovább lépnél.</p>
              )}
            </div>
          )}
        </div>

        {/* Calendar */}
        <div className="calendar-section" ref={calendarRef}>
          <div className="date-inputs" onClick={() => setCalendarOpen(!calendarOpen)}>
            <input
              type="text"
              value={format(startDate, 'yyyy-MM-dd')}
              readOnly
              ref={dateInputRef}
            />
          </div>
          {calendarOpen && (
            <div className="calendar-dropdown">
              <Calendar
                date={startDate}
                onChange={(date) => setStartDate(date)}
                minDate={new Date()}
                maxDate={addDays(new Date(), 365)}
                locale={hu}
                firstDayOfWeek={1}
              />
            </div>
          )}
        </div>

        {/* Event filter buttons */}
        <div className="event-filter-types">
          {Object.keys(eventIcons).map((type) => (
            <button
              key={type}
              className={`event-icon-button ${filters[type] ? 'active' : ''}`}
              onClick={() => handleEventTypeClick(type)}
            >
              <img
                src={filters[type] ? eventIcons[type].colored : eventIcons[type].colorless}
                alt={type}
                className="event-icon-image"
              />
              <span className="event-icon-text">{type}</span>
            </button>
          ))}
        </div>
      </div>

      {/* Right side */}
      <div className="top-section-right">
        {selectedAccommodation && (
          <div className={`view-switch-buttons ${buttonsVisible ? 'visible' : ''}`}>
            <button onClick={handleToggleView} className="toggle-view-button">
              {currentView === 'events-map' ? 'Kereslet naptár' : 'Vissza'}
            </button>
          </div>
        )}

        {/* Suggestion button */}
        <div className="suggestion-button-container">
          <button
            onClick={handleButtonClick}
            disabled={suggestionButtonState === 'loading'}
            className={`suggestion-button ${
              suggestionButtonState === 'loading'
                ? 'loading'
                : suggestionButtonState === 'completed'
                ? 'completed'
                : ''
            }`}
          >
            {suggestionButtonState === 'idle' && 'Ár javaslat'}
          </button>
        </div>
      </div>
    </div>
  );
}
}
export default TopSection;
